<template>
  <v-layout column class="flex-none">
    <v-layout wrap align-center>
      <span class="grey--text text--darken-2 headline">{{ $t('bookings.visitors') }}</span>
      <v-layout wrap justify-end align-center class="mr-2">
        <v-chip v-if="someAssignedWristband" color="green lighten-3" class="subtitle-1 my-2" label>{{ $t('bookings.assigned_wristbands') }}</v-chip>
        <!-- CANCEL BOOKING BTN -->
        <tooltip-button v-if="bookings.guests.length && !isBookingCancelled"
          :disabled="!!hotelGuests.length || isOnlineTicketingBooking"
          :disabledTooltip="!(!!hotelGuests.length || isOnlineTicketingBooking)"
          @click="triggerOpenCancelConfirm = new Date()"
          :tooltipText="!!hotelGuests.length
            ? $t('guests.actions.disable_all_unavailable_guests')
            : $t('guests.actions.disable_all_unavailable_ticketing')"
          :text="$t('guests.actions.disable_all')"
          :loading="loadingCancelBooking"
          buttonClass="my-2 ml-2"
          outlined
        />
      </v-layout>
    </v-layout>
    <item-list
      :listId="table"
      :list="bookingGuests"
      :availableHeaders="headers"
      :refreshColumnDecoration="refreshColumnDecoration"
      decorateColumnAction="bookings/decorateGuestColumn"
      class="mt-2"
    >
      <template v-slot:name="{ item }">
        <v-layout row align-center>
          <v-icon v-if="isGuestDisabled(item)" color="red" class="mr-1">mdi-power</v-icon>
          <v-icon v-if="isGuestChild(item)" class="mr-1">mdi-baby-face-outline</v-icon>
          <loading-field
            :value="getGuestFullName(item, true)"
            :willHaveValue="true"
          />
        </v-layout>
      </template>
      <template v-slot:appendActions="{ item }">
        <v-layout wrap justify-end align-center :set="disabledGuest = isGuestDisabled(item)">
          <template v-if="item.booking">
            <!-- ASSIGN WRISTBAND / WRISTBAND ID-->
            <tooltip-button v-if="!findWristbandExternalId(item) && loadingGuestId !== item.id"
              :disabled="!isParkBooking(item.booking) || disabledGuest || disconnectedPeripheralService || !selectedReader"
              :disabledTooltip="!(!isParkBooking(item.booking) || disabledGuest || disconnectedPeripheralService || !selectedReader)"
              :text="$t('guests.assign_wristband')"
              :tooltipText="disabledGuest
                ? $t('guests.visitor_disabled')
                : disconnectedPeripheralService
                  ? $t('peripherals.warnings.disconnected_peripheral_service')
                  : !selectedReader
                    ? $t('peripherals.warnings.reader_not_configured')
                    : $t('guests.non_assignable_wristband_hotel_guest')"
              @click="assignTag(item)"
              outlined
            />
            <v-progress-circular v-else-if="loadingGuestId === item.id" indeterminate color="primary" />
            <v-chip v-else label color="primary" small class="mr-1">
              {{ `${$t('fields.id')} ${findWristbandExternalId(item).id}` }}
            </v-chip>
          </template>
          <!-- BUY ADDONS -->
          <tooltip-button
            :text="$t('bookings.purchase_packages')"
            :disabled="disabledGuest || !item.accountId || !printerShift"
            :disabledTooltip="!(disabledGuest || !item.accountId || !printerShift)"
            :tooltipText="disabledGuest
              ? $t('guests.visitor_disabled')
              : !item.accountId
                ? $t('guests.account_required')
                : $t('shifts.required_shift.open_shift_required')"
            outlined
            @click="goToPackageOrder(item)"
          />
          <!-- GUEST DETAILS -->
          <v-btn outlined @click="$router.push({ name: routeNames.guest.name, params: { id: item.id } })">
            {{ $t('bookings.details') }}
          </v-btn>
        </v-layout>
      </template>
    </item-list>
    <confirm-modal
      :triggerOpen="triggerOpenCancelConfirm"
      :text="$t('guests.confirm.disable_all')"
      :title="$t('default_content.warning')"
      @accept="handleBookingCancellation()"
    />
    <supervisor-modal :triggerOpen="openSupervisorModal" @finished="cancelBooking()"/>
    <assign-reader v-if="state.assignReader.open" @update="$emit('update')" />
  </v-layout>
</template>

<script>
import { getGuestFullName, isGuestDisabled, findWristbandExternalId } from '@/utils/GuestUtils'
import { GuestRequests } from '@/services/guest.requests'
import { mapState, mapGetters } from 'vuex'
import routeNames from '@/router/routes'
import Vue from 'vue'

const guestService = new GuestRequests()

export default {
  props: {
    refresh: Date
  },
  data () {
    return {
      table: 'BookingGuests',
      headers: [
        { text: this.$t('fields.id'), path: 'id', show: false },
        { text: this.$t('fields.name'), path: 'name', show: true }
      ],
      refreshColumnDecoration: new Date(),
      loadingCancelBooking: false,
      loadingGuestId: null,
      getGuestFullName,
      isGuestDisabled,
      findWristbandExternalId,
      triggerOpenCancelConfirm: null,
      openSupervisorModal: null,
      routeNames
    }
  },
  computed: {
    ...mapState(['bookings', 'state']),
    ...mapState({
      disconnectedPeripheralService: state => state.peripherals.disconnectedPeripheralService,
      selectedReader: state => state.peripherals.selectedReader
    }),
    ...mapGetters({
      isGuestChild: 'configuration/isGuestChild',
      isParkBooking: 'configuration/isParkBooking',
      isBookingCancelled: 'bookings/isBookingCancelled',
      isSupervisor: 'shifts/isSupervisor',
      printerShift: 'shifts/printerShift'
    }),
    bookingGuests () {
      return this.bookings.guests.sort((a, b) => {
        if (getGuestFullName(a, true) > getGuestFullName(b, true)) {
          return 1
        } return -1
      })
    },
    someAssignedWristband () {
      return this.bookings.guests.some((guest) => {
        return !!findWristbandExternalId(guest)
      })
    },
    hotelGuests () {
      return this.bookings.guests.filter((guest) => {
        return guest.booking && !this.isParkBooking(guest.booking)
      })
    },
    isOnlineTicketingBooking () {
      return this.bookings.read && this.bookings.read.isOnlineTicketingBooking
    }
  },
  watch: {
    'bookings.guests.length' () {
      this.refreshColumnDecoration = new Date()
    }
  },
  methods: {
    handleBookingCancellation () {
      if (this.isSupervisor) {
        this.cancelBooking()
      } else {
        this.openSupervisorModal = new Date()
      }
    },
    cancelBooking () {
      this.loadingCancelBooking = true
      this.$store.dispatch('bookings/disableAllGuests')
        .then(() => {
          this.$store.dispatch('bookings/getGuests', { id: this.$route.params.id, save: true, decorate: true }).then(() => {
            this.refreshColumnDecoration = new Date()
          })
        })
        .finally(() => {
          this.loadingCancelBooking = false
        })
    },
    goToPackageOrder (guest) {
      this.$store.commit('packageOrder/setGuestId', guest.id)
      this.$router.push({ name: routeNames.package_order.name })
    },
    assignTag (guest) {
      this.loadingGuestId = guest.id
      this.$store.dispatch('guests/get', { id: guest.id, decorate: true }).then((decoratedGuest) => {
        this.$store.commit('state/setAssignReaderState', [decoratedGuest])
      }).finally(() => {
        this.loadingGuestId = null
      })
    }
  },
  components: {
    ItemList: () => import('@/components/app-components/listing/ItemList.vue'),
    TooltipButton: () => import('@/components/app-components/buttons/TooltipButton.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    AssignReader: () => import('@/components/app-components/modals/AssignTagOverlay.vue'),
    LoadingField: () => import('@/components/app-components/LoadingField.vue'),
    SupervisorModal: () => import('@/components/view-components/modals/SupervisorModal.vue')
  },
  name: 'BookingGuests'
}
</script>

<style lang="scss" scoped>
::v-deep {
  .v-btn--outlined {
    max-width: -webkit-fill-available;
    .v-btn__content {
      display: inline-block;
      max-width: -webkit-fill-available;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
</style>
