var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{staticClass:"flex-none",attrs:{"column":""}},[_c('v-layout',{attrs:{"wrap":"","align-center":""}},[_c('span',{staticClass:"grey--text text--darken-2 headline"},[_vm._v(_vm._s(_vm.$t('bookings.visitors')))]),_c('v-layout',{staticClass:"mr-2",attrs:{"wrap":"","justify-end":"","align-center":""}},[(_vm.someAssignedWristband)?_c('v-chip',{staticClass:"subtitle-1 my-2",attrs:{"color":"green lighten-3","label":""}},[_vm._v(_vm._s(_vm.$t('bookings.assigned_wristbands')))]):_vm._e(),(_vm.bookings.guests.length && !_vm.isBookingCancelled)?_c('tooltip-button',{attrs:{"disabled":!!_vm.hotelGuests.length || _vm.isOnlineTicketingBooking,"disabledTooltip":!(!!_vm.hotelGuests.length || _vm.isOnlineTicketingBooking),"tooltipText":!!_vm.hotelGuests.length
          ? _vm.$t('guests.actions.disable_all_unavailable_guests')
          : _vm.$t('guests.actions.disable_all_unavailable_ticketing'),"text":_vm.$t('guests.actions.disable_all'),"loading":_vm.loadingCancelBooking,"buttonClass":"my-2 ml-2","outlined":""},on:{"click":function($event){_vm.triggerOpenCancelConfirm = new Date()}}}):_vm._e()],1)],1),_c('item-list',{staticClass:"mt-2",attrs:{"listId":_vm.table,"list":_vm.bookingGuests,"availableHeaders":_vm.headers,"refreshColumnDecoration":_vm.refreshColumnDecoration,"decorateColumnAction":"bookings/decorateGuestColumn"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
          var item = ref.item;
return [_c('v-layout',{attrs:{"row":"","align-center":""}},[(_vm.isGuestDisabled(item))?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"red"}},[_vm._v("mdi-power")]):_vm._e(),(_vm.isGuestChild(item))?_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-baby-face-outline")]):_vm._e(),_c('loading-field',{attrs:{"value":_vm.getGuestFullName(item, true),"willHaveValue":true}})],1)]}},{key:"appendActions",fn:function(ref){
          var item = ref.item;
return [_c('v-layout',{attrs:{"wrap":"","justify-end":"","align-center":"","set":_vm.disabledGuest = _vm.isGuestDisabled(item)}},[(item.booking)?[(!_vm.findWristbandExternalId(item) && _vm.loadingGuestId !== item.id)?_c('tooltip-button',{attrs:{"disabled":!_vm.isParkBooking(item.booking) || _vm.disabledGuest || _vm.disconnectedPeripheralService || !_vm.selectedReader,"disabledTooltip":!(!_vm.isParkBooking(item.booking) || _vm.disabledGuest || _vm.disconnectedPeripheralService || !_vm.selectedReader),"text":_vm.$t('guests.assign_wristband'),"tooltipText":_vm.disabledGuest
              ? _vm.$t('guests.visitor_disabled')
              : _vm.disconnectedPeripheralService
                ? _vm.$t('peripherals.warnings.disconnected_peripheral_service')
                : !_vm.selectedReader
                  ? _vm.$t('peripherals.warnings.reader_not_configured')
                  : _vm.$t('guests.non_assignable_wristband_hotel_guest'),"outlined":""},on:{"click":function($event){return _vm.assignTag(item)}}}):(_vm.loadingGuestId === item.id)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}}):_c('v-chip',{staticClass:"mr-1",attrs:{"label":"","color":"primary","small":""}},[_vm._v(" "+_vm._s(((_vm.$t('fields.id')) + " " + (_vm.findWristbandExternalId(item).id)))+" ")])]:_vm._e(),_c('tooltip-button',{attrs:{"text":_vm.$t('bookings.purchase_packages'),"disabled":_vm.disabledGuest || !item.accountId || !_vm.printerShift,"disabledTooltip":!(_vm.disabledGuest || !item.accountId || !_vm.printerShift),"tooltipText":_vm.disabledGuest
            ? _vm.$t('guests.visitor_disabled')
            : !item.accountId
              ? _vm.$t('guests.account_required')
              : _vm.$t('shifts.required_shift.open_shift_required'),"outlined":""},on:{"click":function($event){return _vm.goToPackageOrder(item)}}}),_c('v-btn',{attrs:{"outlined":""},on:{"click":function($event){return _vm.$router.push({ name: _vm.routeNames.guest.name, params: { id: item.id } })}}},[_vm._v(" "+_vm._s(_vm.$t('bookings.details'))+" ")])],2)]}}])}),_c('confirm-modal',{attrs:{"triggerOpen":_vm.triggerOpenCancelConfirm,"text":_vm.$t('guests.confirm.disable_all'),"title":_vm.$t('default_content.warning')},on:{"accept":function($event){return _vm.handleBookingCancellation()}}}),_c('supervisor-modal',{attrs:{"triggerOpen":_vm.openSupervisorModal},on:{"finished":function($event){return _vm.cancelBooking()}}}),(_vm.state.assignReader.open)?_c('assign-reader',{on:{"update":function($event){return _vm.$emit('update')}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }